import sanityClient from './sanityClient'; // Adjust the path as needed
import imageUrlBuilder from '@sanity/image-url'; // Import the image URL builder

const builder = imageUrlBuilder(sanityClient);

// Define a function to build image URLs
const imageUrlFor = (source) => {
  return builder.image(source).url();
};

export const fetchImages = async () => {
  const query = `*[_type == "imageAsset"]{
    _id, // Include the _id field
    title,
    description,
    "imageUrl": image.asset->url, // Fetch the URL of the image asset
    caption,
    alt
  }`; // Adjust the query for your specific schema
  try {
    const data = await sanityClient.fetch(query);
    
    // Add URLs to the image objects
    const imagesWithUrls = data.map(image => ({
      ...image,
      imageUrl: imageUrlFor(image.imageUrl), // Generate the actual URL for each image
    }));

    return imagesWithUrls;
  } catch (error) {
    console.error("Error fetching images", error);
    return [];
  }
};




