// Navigation.js
import React, { useState, useEffect } from 'react';
import StickyNav from './StickyNav'; // Import the StickyNav component
import { fetchImages } from '../lib/fetchData'; // Adjust the import path as needed

const Navigation = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImages().then(setImages);
  }, []);

  return (
    <><nav className="sticky-nav">
      <StickyNav /> {/* Include the StickyNav component */}
    </nav><div className="navigation-images">
        {images.map(image => (
          <div
            key={image._id}
            className="image-container"
            style={{ backgroundImage: `url(${image.imageUrl})` }}
          >
            {/* Optional content inside image container */}
          </div>
        ))}
      </div></>
  );
};

export default Navigation;

