// ThemeContext.js
import React from 'react';

const ThemeContext = React.createContext({
  theme: 'light', // Default theme
  toggleTheme: () => {}, // Placeholder for a function to toggle the theme
});

export default ThemeContext;

