
import React, { useContext } from 'react';
import ThemeContext from '../utils/ThemeContext';

const ProjectComponent = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`project-container ${theme}`}>
      {/* Projects or content */}
    </div>
  );
};

export default ProjectComponent;
