import React from 'react';
import Navigation from './components/navigation';
import './App.css';
import './components/navigation1.css';
import ProjectContainer from './components/ProjectContainer';
import ThemeProvider from './utils/ThemeProvider';

function App() {
  return (
    <ThemeProvider>
      <div className="App">
        <Navigation></Navigation>
        <ProjectContainer />
      </div>
    </ThemeProvider>
  );
}

export default App;

