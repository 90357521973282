// sanityClient.js
import { createClient } from '@sanity/client';

const sanityClient = createClient({
  projectId: '2jl7b5q7', // your project ID
  dataset: 'production', // your dataset name
  apiVersion: '2024-01-28', // use a specific version of the Sanity API
  useCdn: true, // `false` for fresh data
});

export default sanityClient;
