// StickyNav.js
import React from 'react';
import './StickyNav.css';
import ThemeToggleButton from './ThemeToggleButton';

function StickyNav() {
    return (
        <header className="sticky-nav-header">
            <div className="nav-container1">
                <a href="https://www.efeozalpp.com" className="h3-logo"><h3>E0</h3></a>
            </div>
            <div className="nav-container2">
                {/* Burger Menu */}
                <ThemeToggleButton />
                <div className="menu-toggle">
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
            </div>
        </header>
    );
}

export default StickyNav;
