import React, { useContext, useEffect, useRef } from 'react';
import ThemeContext from '../utils/ThemeContext';
import lottie from 'lottie-web';

const ThemeToggleButton = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const animationContainer = useRef(null);
  const animation = useRef(null);

  useEffect(() => {
    animation.current = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '/ToggleAnimation.json',
    });

    // Set the speed of the animation (e.g., 2 for double speed)
    animation.current.setSpeed(2);

    // Set the initial frame based on the current theme
    const initialFrame = theme === 'light' ? 45 : 105;
    animation.current.goToAndStop(initialFrame, true);

    animation.current.addEventListener('complete', () => {
      toggleTheme();
    });

    return () => {
      animation.current.removeEventListener('complete', toggleTheme);
      animation.current.destroy();
    };
  }, [theme]);

  const handleToggle = () => {
    const segments = theme === 'light' ? [45, 105] : [105, 45];
    animation.current.playSegments(segments, true);
  };

  return (
    <button className="small-icon-button" onClick={handleToggle}>
      <div ref={animationContainer}></div>
    </button>
  );
};

export default ThemeToggleButton;







